.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.bg-blue {
  background-color: #61dafb;
}

.page-container {
  display: flex;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  width: 100vw;
}

.home-container {
  display: flex;
  align-content: center;
  flex-direction: column;

}

.leader-container {
  display: flex;
  align-content: center;
  flex-direction: column;
 width: 100vw;
}

.header-text {
  color: #2A2A2A;
}

.bg-light-blue {
  background-color: #E0F3FF;
}

.search-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  padding-left: 2%;
  padding-right: 2%;
  width: 95vw;
}

.search-bar {
  width: 100%;
  background-color: #F5F5F5;
  color: #2A2A2A;
  border: none;
  font-size: 0.9em;
  padding: 11px;
  border-radius: 20px;
  margin-bottom: 10px;
  transition: all 0.5s;
  outline: none;
}

.search-bar:focus {
  width: 90%;
  background-color: #ebebeb;
  color: #2A2A2A;
  border: none;
  font-size: 0.9em;
  padding: 11px;
  border-radius: 20px;
  margin-bottom: 10px;
  transition: all 0.5s;
  outline: none;
}

.lesson-card-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5%;
  width: 90vw;
  transition: all 0.5s;
}

.lesson-card {
  background-color: #D9F1FF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  margin: 5px;
  padding: 10px;
  transition: all 0.5s;
}

.lesson-card:hover {
  background-color: #8acef5;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  border-radius: 10px;
  margin: 5px;
  padding: 10px;
  transition: all 0.5s;
}

.lesson-card h4 {
  font-size: 1em;
  font-weight: 400;
}

.top-bar {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  width: 100vw;
  padding: 2%;
  padding-left: 5%;
  align-self: center;
}

.fixed-top-bar {
  position: fixed;
  z-index: 99;
}

.top-bar h4 {
  font-size: 1.2em;
  margin-left: 5%;
  font-weight: 400;
}

.mb {
  margin-bottom: 20px;
}

.input-text {
  background-color: #FFFFFF;
  color: #2A2A2A;
  width: 80vw;
  border: none;
  font-size: 0.9em;
  padding: 11px;
  border-radius: 20px;
  margin-bottom: 10px;
  transition: all 0.5s;
}

.home-profile-bar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
}

.text-username {
  font-size: 1.1em;
  margin-left: 2vw;
  font-weight: 300;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 40vw;
}

.username {
  font-size: 1.2em;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 150px;
}

.notification {
  background-color: #0088ff;
  border: none;
  border-radius: 20px;
  width: 90vw;
  margin: 5px;
  padding-left: 15px;
  padding-right: 15px;
  color: #FFFFFF;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.menu {
  display: flex;
  background-color: rgba(255, 255, 255, 0.99);
 height: 100%;
  width: 80vw;
  z-index: 999;
  flex-direction: column;
  -webkit-box-shadow: 12px 0px 8px -6px rgba(0, 0, 0, 0.21);
  box-shadow: 12px 0px 8px -6px rgba(0, 0, 0, 0.21);
  transition: all 1s;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 10px;
  
}

.menu h4 {
  margin: 10px;
  font-size: 1.2em;
  font-weight: 400;
  transition: all 1s;
}

.menu-header {
  margin: 10px;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
  transition: all 1s;
}

#slide {
  left: -500px;
  height: 100vh;
  width: 80vw;
  animation: slide 0.5s forwards;
  animation-delay: 0s;
  transition: all 1s;
}



.menu-btn {
  margin: 0px;
}

.menu-btn:hover {
  filter: brightness(100%) contrast(20%);
  transition: all 1s;
}

.menu-btn:active {
  filter: brightness(100%) contrast(20%);
  transition: all 1s;
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}



.check {
  border: none;
  font-size: 2em;
  background-color: #61dafb;
}

.menu-item {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 8px;
  border: none;
  background-color:transparent;
  padding: 10px;
  border-radius: 10px;
  color: #444444;
  text-align: left;
}

.menu-item:hover {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 8px;
  border: none;
  background-color: #d1d1d1;
  padding: 10px;
  border-radius: 10px;
  color: #444444;
  text-align: left;
}

.menu-item:active {
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 8px;
  border: none;
  background-color: #d1d1d1;
  padding: 10px;
  border-radius: 10px;
  color: #444444;
  text-align: left;
}

.profile-img {
  margin-left: 5vw;
}

.profile-img:hover {
  filter: brightness(90%) contrast(90%);
}

.profile-img:active {
  filter: brightness(90%) contrast(90%);
}

.content-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
}

.main-banner {
  background-color: #8AD2FF;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 85vw;
  align-self: center;
  border-radius: 15px;
  padding: 10px;
  border: solid #DDEFFB 5px;
}

.banner-text-head {
  font-size: 1.2em;
  padding: 10px;
}

.banner-text-sub {
  font-size: 0.5em;
  padding: 10px;
}

.text-see-all {
  margin-right: 5vw;
  color: #0773FF;
}

a:hover, a:visited, a:link, a:active {
  text-decoration: none;
}

.content-card {
  background-color: #818EFF;
  display: flex;
  flex-direction: column;
  width: 45%;
  margin: 1vw;
  font-size: 1em;
  border-radius: 10px;
  text-overflow: ellipsis;
  align-items: center;
  color: #FFFFFF;
  border: none;
  transition: all 0.5s;
  outline: none;
}

.quiz-container {
  display: flex;
  background-color: #D9F1FF;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  height: 100vh;
  padding: 10px;
  transition: all 0.5s;
}

.leader-card {
  background-color: #d6f6ff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100vw;
  border-radius: 10px;
  padding-left: 15px;
  padding-right: 15px;
  height: 60px;
}

.quiz-select {
  background-color: #69B9EB;
  border: none;
  font-size: 1em;
  padding: 10px;
  border-radius: 10px;
  color: #FFFFFF;
  outline: none;
}

.select-container {
  display: flex;
  flex-direction: column;
  align-content: space-between;
  justify-content: center;
  height: 100vh;
  margin: 5%;
}

.quiz-title {
  font-size: 1.5em;
  font-weight: 500;
  display: flex;
  align-self: center;
  text-align: center;
  transition: all 0.5s;
}

.quiz-ans {
  margin: 5px;
  width: 60vw;
  padding: 10px;
  border-radius: 15px;
  background-color: #818EFF;
  color: #FFFFFF;
  border: none;
  font-size: 1.1em;
  font-weight: 300;
  transition: all 0.5s;
}

.quiz-ans:hover {
  margin: 5px;
  width: 60vw;
  padding: 10px;
  border-radius: 15px;
  background-color: #6b79fa;
  color: #FFFFFF;
  border: none;
  font-size: 1.1em;
  font-weight: 300;
  transition: all 0.5s;
}

.quiz-number {
  font-size: 2em;
  transition: all 0.5s;
  text-align: center;
}

.score-container {
  display: flex;
  align-content: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  background-color: #D9F1FF;
  text-align: center;
}

.score-container h2 {
  font-size: 2em;
  font-weight: 200;
}

.score-container h1 {
  font-size: 3em;
  font-weight: 300;
}

.content-card:focus {
  background-color: #3B4EF8;
  display: flex;
  flex-direction: column;
  width: 45%;
  margin: 1vw;
  font-size: 1em;
  border-radius: 10px;
  text-overflow: ellipsis;
  align-items: center;
  color: #FFFFFF;
  border: none;
  transition: all 0.5s;
  outline: none;
}

.profile-container {
  background-color: #CEECFE;
  display: flex;
  flex-direction: column;
  height:100vh;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.profile-container img {
  margin-top: 5%;
  width: 20vw;
}

.profile-container h4 {
  font-size: 1.3em;
  font-weight: 400;
}

.modal {
  position: absolute;
  display: flex;
  height: 100vh;
  flex-direction: column;
  width: 100vw;
  align-items: center;
  justify-content: center;
  background-color: #61dafb;
}

.alert-red {
  background-color: #ff3737;
  color: #FFFFFF;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 20px;
  height: 2em;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 5px;
  display: flex;
}

.alert-text {
  font-size: 0.9em;
}

.modal h4 {
  font-size: 1.2em;
  text-align: center;
  font-weight: 400;
  margin: 20%;
}

.text-see-all:focus {
  margin-right: 5vw;
  color: #095ecc;
  font-weight: 600;
}

.home-row-card {
  background-color: #CEECFE;
  display: flex;
  flex-direction: column;
  width: 35%;
  margin: 2vw;
  padding: 15px;
  border-radius: 10px;
  border: none;
  text-overflow: ellipsis;
}

.home-card-subtext {
  font-size: 0.8em;
}

.home-card-text {
  font-size: 1em;
  text-overflow: ellipsis;
  overflow: hidden;
}

.btn-watch-home {
  background-color: #FA7A29;
  border: none;
  font-size: 0.9em;
  padding: 3px;
  border-radius: 15px;
  outline: none;
  padding: 5px;
  color: #FFFFFF;
  transition: all 0.5s;
  width: 100%;
}

.btn-watch-home:focus {
  background-color: #eb6411;
  border: none;
  font-size: 0.9em;
  padding: 3px;
  border-radius: 15px;
  outline: none;
  padding: 5px;
  color: #FFFFFF;
  transition: all 0.5s;
  width: 100%;
}

.home-card-container {
  display: flex;
  flex-direction: row;
  padding-left: 5vw;
  padding-right: 5vw;
  justify-content: center;
  flex-wrap: wrap;
}

.text-content-header {
  margin-left: 5vw;
  font-size: 1.1em;
}

.input-text:focus {
  background-color: #94CCFC;
  color: #2A2A2A;
  width: 85vw;
  border: none;
  font-size: 0.9em;
  padding: 11px;
  border-radius: 20px;
  outline: none;
  transition: all 0.5s;
}

.input-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.note-text {
  font-size: 0.8em;
  margin: 10px;
  color: #2A2A2A;
  text-align: center;
}

.btn-primary {
  background: #69B9EB;
  width: 80%;
  color: #FFFFFF;
  border: none;
  font-size: 1em;
  padding: 10px;
  transition: all 0.5s;
  border-radius: 20px;
}

.btn-primary:focus {
  background: #0C96EC;
  width: 80%;
  color: #FFFFFF;
  border: none;
  font-size: 1em;
  padding: 10px;
  border-radius: 20px;
  outline: none;
  transition: all 0.5s;
}

.header-image {
  width: 100%;
}

.btn-secondary {
  background: #8E8E8E;
  width: 80%;
  color: #FFFFFF;
  border: none;
  font-size: 1em;
  padding: 10px;
  border-radius: 20px;
  transition: all 0.5s;
}

.btn-secondary:focus {
  background: #777575;
  width: 80%;
  color: #FFFFFF;
  border: none;
  font-size: 1em;
  padding: 10px;
  border-radius: 20px;
  outline: none;
  transition: all 0.5s;
}

.sub-head {
  color: #2A2A2A;
  font-weight: 400;
  font-size: 1.1em;
}

.header-text {
  font-size: 1.2em;
  text-align: center;
}

.mt {
  margin-top: 5px;
}

.mt-20 {
  margin-top: 20px;
}

.pyro>.before, .pyro>.after {
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  box-shadow: -120px -218.66667px blue, 248px -16.66667px #00ff84, 190px 16.33333px #002bff, -113px -308.66667px #ff009d, -109px -287.66667px #ffb300, -50px -313.66667px #ff006e, 226px -31.66667px #ff4000, 180px -351.66667px #ff00d0, -12px -338.66667px #00f6ff, 220px -388.66667px #99ff00, -69px -27.66667px #ff0400, -111px -339.66667px #6200ff, 155px -237.66667px #00ddff, -152px -380.66667px #00ffd0, -50px -37.66667px #00ffdd, -95px -175.66667px #a6ff00, -88px 10.33333px #0d00ff, 112px -309.66667px #005eff, 69px -415.66667px #ff00a6, 168px -100.66667px #ff004c, -244px 24.33333px #ff6600, 97px -325.66667px #ff0066, -211px -182.66667px #00ffa2, 236px -126.66667px #b700ff, 140px -196.66667px #9000ff, 125px -175.66667px #00bbff, 118px -381.66667px #ff002f, 144px -111.66667px #ffae00, 36px -78.66667px #f600ff, -63px -196.66667px #c800ff, -218px -227.66667px #d4ff00, -134px -377.66667px #ea00ff, -36px -412.66667px #ff00d4, 209px -106.66667px #00fff2, 91px -278.66667px #000dff, -22px -191.66667px #9dff00, 139px -392.66667px #a6ff00, 56px -2.66667px #0099ff, -156px -276.66667px #ea00ff, -163px -233.66667px #00fffb, -238px -346.66667px #00ff73, 62px -363.66667px #0088ff, 244px -170.66667px #0062ff, 224px -142.66667px #b300ff, 141px -208.66667px #9000ff, 211px -285.66667px #ff6600, 181px -128.66667px #1e00ff, 90px -123.66667px #c800ff, 189px 70.33333px #00ffc8, -18px -383.66667px #00ff33, 100px -6.66667px #ff008c;
  -moz-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -webkit-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -o-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  -ms-animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
  animation: 1s bang ease-out infinite backwards, 1s gravity ease-in infinite backwards, 5s position linear infinite backwards;
}

.pyro>.after {
  -moz-animation-delay: 1.25s, 1.25s, 1.25s;
  -webkit-animation-delay: 1.25s, 1.25s, 1.25s;
  -o-animation-delay: 1.25s, 1.25s, 1.25s;
  -ms-animation-delay: 1.25s, 1.25s, 1.25s;
  animation-delay: 1.25s, 1.25s, 1.25s;
  -moz-animation-duration: 1.25s, 1.25s, 6.25s;
  -webkit-animation-duration: 1.25s, 1.25s, 6.25s;
  -o-animation-duration: 1.25s, 1.25s, 6.25s;
  -ms-animation-duration: 1.25s, 1.25s, 6.25s;
  animation-duration: 5s, 1.25s, 6.25s;
}

@-webkit-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white;
  }
}

@-moz-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white;
  }
}

@-o-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white;
  }
}

@-ms-keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white;
  }
}

@keyframes bang {
  from {
    box-shadow: 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white, 0 0 white;
  }
}

@-webkit-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-moz-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-o-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-ms-keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@keyframes gravity {
  to {
    transform: translateY(200px);
    -moz-transform: translateY(200px);
    -webkit-transform: translateY(200px);
    -o-transform: translateY(200px);
    -ms-transform: translateY(200px);
    opacity: 0;
  }
}

@-webkit-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@-moz-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@-o-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@-ms-keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}

@keyframes position {
  0%, 19.9% {
    margin-top: 10%;
    margin-left: 40%;
  }
  20%, 39.9% {
    margin-top: 40%;
    margin-left: 30%;
  }
  40%, 59.9% {
    margin-top: 20%;
    margin-left: 70%;
  }
  60%, 79.9% {
    margin-top: 30%;
    margin-left: 20%;
  }
  80%, 99.9% {
    margin-top: 30%;
    margin-left: 80%;
  }
}